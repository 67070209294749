import { Grid, Typography } from '@mui/material';
import React from 'react';
import './infoRanking.css';
function InfoRanking({ isMobile, activeItem, nextUpdateTime }) {
 

    // Función para renderizar el contenido según activeItem
    const renderContent = () => {
        switch (activeItem) {
            case 'resets':
                return (
                    <Grid style={{border:'1px solid gray', padding:'1.5625rem 2.1875rem 1.5625rem 2rem', borderRadius:'5px'}}>

                        <Typography variant='h4' style={{ color: 'white', marginBottom: '10px', fontWeight:500, fontSize:'18px', padding: 3 }} className='infoOnline'>¿Cómo entrar al ranking de Resets?</Typography>
                        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify', fontSize:'.8rem' }}>
                                Mata monstruos dentro del juego para conseguir puntos de experiencia. A medida que subas tu experiencia, subirás de nivel. Cuando llegues a nivel 400, habla con "Reset NPC" en Lorencia para resetear tu personaje.
                            </Typography>
                            <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify', fontSize:'.8rem' }}>
                                Una vez reseteado, tu personaje volverá al nivel 1. Vuelve a jugar, a subir de nivel y resetea tu personaje la mayor cantidad de veces posible.
                            </Typography>
                            <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify', fontSize:'.8rem' }}>
                                La máxima cantidad de resets es 400. Una vez que llegues a esa cantidad, entrarás al Salón de la Fama de Mu Serendipia y podrás participar en eventos exclusivos.
                            </Typography>
                            <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify', fontSize:'.8rem' }}>
                                No aparecen en el ranking personajes cuya cuenta está bloqueada, ni personajes inactivos.
                            </Typography>
                        </Grid>

                        <Grid style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '20px' }}>
                            <Typography variant='h4' style={{ color: 'white', marginBottom: '10px', fontWeight:500, fontSize:'18px', padding: 3 }} className='infoOnline'>Tips</Typography>
                            <Grid style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <Typography variant='body1' style={{  textAlign: 'justify', color:'#9ba5b4' , fontSize:'.8rem'}}>
                                    Jugando en party se vuelve más ameno el leveleo. Te resultará más fácil conseguir compañeros de party utilizando nuestro buscador de parties. Presiona la tecla [P] para obtener más información.
                                </Typography>
                                <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify', fontSize:'.8rem' }}>
                                    Nosotros no hacemos distinción del personaje que hayas elegido para jugar. Todos los personajes que se sumen a una party otorgarán un 1% más de experiencia al grupo, pudiéndose hacer parties de hasta 10 personajes.
                                </Typography>
                                <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify' , fontSize:'.8rem'}}>
                                    Puedes subir de experiencia más rápidamente si aprovechas los eventos de Devil Square y Blood Castle.
                                </Typography>
                                <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify' , fontSize:'.8rem'}}>
                                    Revisa nuestra tienda presionando la tecla [X], encontrarás artículos exclusivos para mejorar tu entrenamiento.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            case 'online':
                return (
                    <Grid style={{border:'1px solid gray', padding:'1.5625rem 2.1875rem 1.5625rem 2rem', borderRadius:'5px'}}>
                        <Typography variant='h4' style={{ color: 'white', marginBottom: '10px', fontWeight:500, fontSize:'18px', padding: 3 }} className='infoOnline'>¿Cómo funciona?</Typography>
                        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '10px', color:'#9ba5b4' }}>
                            <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify', fontSize:'.8rem' }}>
                                El ranking Online se basa en la cantidad de tiempo que los jugadores han estado activos en línea.
                            </Typography>
                            <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify' , color:'#9ba5b4', fontSize:'.8rem'}}>
                                Los jugadores que estén en línea durante más tiempo tendrán una posición más alta en el ranking.
                            </Typography>
                            <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify', color:'#9ba5b4', fontSize:'.8rem' }}>
                                Este ranking se actualiza periódicamente para reflejar los cambios en la actividad en línea de los jugadores.
                            </Typography>
                        </Grid>
                    </Grid>
                );
            case 'sdlf':
                return (
                    <Grid style={{border:'1px solid gray', padding:'1.5625rem 2.1875rem 1.5625rem 2rem', borderRadius:'5px'}}>

                        <Typography variant='h4' style={{ color: 'white', marginBottom: '10px', fontWeight:500, fontSize:'18px', padding: 3 }} className='infoOnline'>¿Qué es el Salón de la Fama?</Typography>
                        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify' , fontSize:'.8rem'}}>
                                Estos son los personajes que alcanzaron la máxima cantidad de resets permitidos (400).
                            </Typography>
                            <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify' , fontSize:'.8rem'}}>
                                No aparecen en el ranking los personajes cuya cuenta está bloqueada.
                            </Typography>
                        </Grid>
                    </Grid>
                );
            case 'pk':
                return (
                    <Grid style={{border:'1px solid gray', padding:'1.5625rem 2.1875rem 1.5625rem 2rem', borderRadius:'5px'}}>

                        <Typography variant='h4'style={{ color: 'white', marginBottom: '10px', fontWeight:500, fontSize:'18px', padding: 3 }} className='infoOnline'>¿Cómo entrar al ranking de Phonomanias?</Typography>
                        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify', fontSize:'.8rem' }}>
                                Obtienes UN Punto PK cada vez que limpias 100 muertes. Si te limpias menor cantidad, no sumas puntaje.
                            </Typography>
                            <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify' , fontSize:'.8rem'}}>
                                A medida que consigas más puntos, tendrás más oportunidades de subir en este ranking.
                            </Typography>
                            <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify', fontSize:'.8rem' }}>
                                Para limpiarte las muertes, habla con "PK's NPC" en Lorencia.
                            </Typography>
                        </Grid>
                    </Grid>
                );
            case 'guilds':
                return (
                    <Grid style={{border:'1px solid gray', padding:'1.5625rem 2.1875rem 1.5625rem 2rem', borderRadius:'5px'}}>

                        <Typography variant='h4' style={{ color: 'white', marginBottom: '10px', fontWeight:500, fontSize:'18px', padding: 3 }} className='infoOnline'>¿Cómo entrar al ranking de Guilds?</Typography>
                        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify', fontSize:'.8rem' }}>
                                Necesitas un guild para ingresar.
                            </Typography>
                            <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify' , fontSize:'.8rem'}}>
                                Para sumar puntos, tu guild puede desafiar a otro guild a una guerra (/war), o bien a un partido de fútbol (/soccer). Cada vez que ganes una batalla o partido, tu guild sumará puntos y se moverá en el ranking.
                            </Typography>
                        </Grid>
                    </Grid>
                );
            default:
                return (
                    <Grid style={{border:'1px solid gray', padding:'1.5625rem 2.1875rem 1.5625rem 2rem', borderRadius:'5px'}}>

                        <Typography variant='h4' style={{ color: 'white', marginBottom: '10px' , fontWeight:500}}>Información general sobre los rankings</Typography>
                        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <Typography variant='body1' style={{ color: '#9ba5b4', textAlign: 'justify', fontSize:'.8rem' }}>
                                Selecciona un ranking en el menú para ver información específica sobre cómo participar y las reglas aplicables.
                            </Typography>
                        </Grid>
                    </Grid>
                );
        }
    };

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : '25%' }}>
            {renderContent()}
            {/* Mostrar el mensaje de próxima actualización si está definido el tiempo */}
            {nextUpdateTime[activeItem] !== undefined && (
                <Typography variant='body1' style={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>
                    Próxima actualización en {nextUpdateTime[activeItem]} minutos
                </Typography>
            )}
        </Grid>
    );
}

export default InfoRanking;
