import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { Button, Grid, Typography } from '@mui/material'
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { MdDeleteForever } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';

function Post(props) {
    const [user, setUser] = useState({});

    /*
    async function getUserByIdTheToken(token) {
        console.log(token);

        try {
            const response = await axios.get('https://backmu.vercel.app/getUserByIdTheToken', {
                headers: {
                    Authorization: `Bearer ${token}`,

                },
            });
            return response.data;
        } catch (error) {
            return {
                error
            }
        }
    }
    useEffect(() => {
        async function getUser() {
            let token = window.localStorage.getItem("token");
            let res = await getUserByIdTheToken(token);

            if (res.username) {
                setUser(res);
            }
        }
        getUser();

    }, []);
    */

    const { temaId, postId } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const fetchPost = async () => {
        try {
            const response = await axios.get(`https://backmu.vercel.app/temas/${temaId}/${postId}`);
            setPost(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error al obtener el tema:', error);

            setLoading(false);
        }
    };
    useEffect(() => {
        fetchPost();
    }, [postId]);
    const [abrirCrear, setAbrirCrear] = useState()
    const [nuevaRespuesta, setNuevaRespuesta] = useState({
        idUsuario: 0,
        contenido: '',
    });
    // Función para crear una nueva respuesta a un post
    const crearRespuesta = async () => {

        try {
            const response = await axios.post(`https://backmu.vercel.app/temas/${temaId}/${postId}/respuestas`, nuevaRespuesta);
            fetchPost()
        } catch (error) {
            console.error('Error al crear la respuesta:', error);
            throw new Error('Error al crear la respuesta');
        }
    };

    // Función para eliminar una respuesta específica
    const eliminarRespuesta = async (respuestaId) => {
        try {
            const response = await axios.delete(`https://backmu.vercel.app/temas/${temaId}/${postId}/respuestas/${respuestaId}`);
            fetchPost()
        } catch (error) {
            console.error('Error al eliminar la respuesta:', error);
            throw new Error('Error al eliminar la respuesta');
        }
    };

    const modalStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo difuminado
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99999999
    };
    return (
        <Layout tyExpanded={props.tyExpanded} setExpanded={props.setExpanded} isMobile={props.isMobile}>
            <Grid style={{ display: 'flex', flexDirection: 'column', gap: '35px',padding: props.isMobile ? '2rem 1rem' :'2rem 5.8rem' }}>
                <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Grid style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <Typography style={{ color: 'white' }}>{post ? post?.titulo?.toUpperCase() : 'Cargando titulo...'}</Typography>
                        <Typography style={{ color: 'white' }}>{post ? post?.contenido : 'Cargando contenido...'}</Typography>
                        
                    </Grid>
                    {
                        user.username &&
                        <Typography style={{ color: 'black', borderRadius: '5px', backgroundColor: 'white', padding: '5px', cursor: 'pointer' }} onClick={() => setAbrirCrear(!abrirCrear)} >Responder Post</Typography>
                    }

                </Grid>



                <Grid style={{ display: 'flex', borderRadius: '5px', border: '1px solid grey', padding: '10px', flexDirection: 'column', gap: '10px' }}>
                    {post?.respuestas?.length > 0

                        ?
                        post?.respuestas?.map((respuesta) => (

                            <Grid style={{ textDecoration: 'none', display: 'flex', gap: '5px', padding: '10px', border: '1px solid gray', width: '100%', borderRadius: '5px', justifyContent: 'space-between', alignItems: 'center' }}>

                                <Grid style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography style={{ color: 'white', fontStyle:'italic', fontWeight:'600' }}>{respuesta.autor?.username?.toUpperCase()} Respondió:</Typography>

                                    <Typography style={{ color: 'white' }}>{respuesta.contenido}</Typography>

                                </Grid>

                                {
                                    user.rol === 'admin' &&
                                    <Grid style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                                        <MdDeleteForever onClick={() => eliminarRespuesta(respuesta._id)} style={{ color: 'white', fontSize: '25px' }} />
                                        <FaEdit style={{ color: 'white', fontSize: '25px' }} />
                                    </Grid>
                                }

                            </Grid>
                        ))
                        :
                        <Typography style={{ color: 'white', fontStyle: 'italic', fontSize: '20px' }}>No existen respuestas en este post</Typography>}
                </Grid>

                {
                    abrirCrear &&

                    <Grid style={modalStyle} >
                        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '15px', backgroundColor: 'grey', width: '50%', padding: '15px', borderRadius: '5px' }}>
                            <IoMdCloseCircle onClick={() => setAbrirCrear(!abrirCrear)} />

                            <Grid style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                <label style={{ color: 'white' }} htmlFor="titulo">Responder:</label>
                                <input
                                    type="text"
                                    id="titulo"
                                    name="titulo"
                                    value={nuevaRespuesta.contenido}
                                    onChange={(e) => setNuevaRespuesta({ ...nuevaRespuesta, idUsuario: user._id, contenido: e.target.value })}
                                    required
                                />
                            </Grid>


                            <Button onClick={() => { crearRespuesta(nuevaRespuesta); setAbrirCrear(!abrirCrear) }}>Responder post</Button>
                        </Grid>
                    </Grid>
                }
            </Grid>

        </Layout>

    )
}

export default Post