import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { Button, Grid, Typography } from '@mui/material'
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { MdDeleteForever } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';

function Tema(props) {
    const [user, setUser] = useState({});

    /*
    async function getUserByIdTheToken(token) {
        console.log(token);

        try {
            const response = await axios.get('https://backmu.vercel.app/getUserByIdTheToken', {
                headers: {
                    Authorization: `Bearer ${token}`,

                },
            });
            return response.data;
        } catch (error) {
            return {
                error
            }
        }
    }
    useEffect(() => {
        async function getUser() {
            let token = window.localStorage.getItem("token");
            let res = await getUserByIdTheToken(token);

            if (res.username) {
                setUser(res);
            }
        }
        getUser();

    }, []);
    */

    const { id } = useParams();
    const [tema, setTema] = useState(null);
    const [loading, setLoading] = useState(true);
    const [posts, setPosts] = useState();
    const [nuevoPost, setNuevoPost] = useState({
        idUsuario: 0,
        titulo: '',
        contenido: '',
    });
    const fetchTema = async () => {
        try {
            const response = await axios.get(`https://backmu.vercel.app/temas/${id}`);
            setTema(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error al obtener el tema:', error);

            setLoading(false);
        }
    };
    useEffect(() => {
        fetchTema();
    }, [id]);


    // Función para obtener la lista de noticias


    const handleCreatePost = async () => {
        try {
            const response = await axios.post(`https://backmu.vercel.app/temas/${id}/posts`, nuevoPost);
            console.log(response.data); // Deberías recibir el mensaje indicando que la noticia se creó exitosamente
            // Actualiza la lista de noticias después de crear una nueva
            fetchTema();
            // Reinicia el formulario
            setNuevoPost({ title: '', contenido: '', idUsuario: 0 });
            setAbrirCrear(!abrirCrear)
        } catch (error) {
            console.error('Error al crear post:', error);
        }
    };

    const borrarPost = async (temaid, postId) => {
        console.log('borrado')
        try {
            const response = await axios.delete(`https://backmu.vercel.app/temas/${temaid}/${postId}`);
            window.location.reload();

        } catch (error) {
            console.error('Error al eliminar el post:', error);
            throw new Error('Error al eliminar el post');
        }
    };
    const [abrirCrear, setAbrirCrear] = useState()
    const modalStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo difuminado
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99999999
    };
    return (
        <Layout tyExpanded={props.tyExpanded} setExpanded={props.setExpanded} isMobile={props.isMobile}>
            <Grid style={{ display: 'flex', flexDirection: 'column', gap: '35px', padding: props.isMobile ? '2rem 1rem' :'2rem 5.8rem' }}>
                <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Grid style={{ color: 'white', display: 'flex', alignItems: 'center',gap:'10px' }}>
                        <Typography style={{ fontSize: '24px', color: 'white' }}>Tema:</Typography>
                        <Typography style={{ fontSize: '24px', color: 'white' }}>{tema ? tema?.titulo?.toUpperCase() : 'Cargando titulo...'}</Typography>
                    </Grid>
                    {
                        user.username &&
                        <Typography style={{ color: 'black', borderRadius: '5px', backgroundColor: 'white', padding: '5px', cursor: 'pointer' }} onClick={() => setAbrirCrear(!abrirCrear)} >Crear Post</Typography>
                    }

                </Grid>

                <Grid style={{ display: 'flex', borderRadius: '5px', border: '1px solid grey', padding: '10px', flexDirection: 'column', gap: '10px' }}>
                    {tema?.posts?.length > 0

                        ?
                        tema?.posts?.map((tema2) => (

                            <Link to={`/foro/${tema._id}/${tema2._id}`} style={{ textDecoration: 'none', display: 'flex', gap: '5px', padding: '10px', border: '1px solid gray', width: '100%', borderRadius: '5px', justifyContent: 'space-between', alignItems: 'center' }}>

                                <Grid style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '50%' }}>
                                    <Typography style={{ color: 'white' }}>{tema2.titulo?.toUpperCase()}</Typography>
                                    <Typography style={{ color: 'white' }}>{tema2.contenido}</Typography>
                                    <Typography style={{ color: 'white' }}>Por: <b>{tema2.autor?.username}</b></Typography>

                                </Grid>

                                <Grid style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography style={{ color: 'white' }}><b>{tema2.respuestas.length} Respuestas</b></Typography>
                                </Grid>
                                <Grid style={{ display: 'flex', flexDirection: 'column', gap: '5px', padding: '15px' }}>
                                    <Typography style={{ color: 'white' }}><b>Última respuesta: {tema2.respuestas[0]?.autor?.username ? tema2.respuestas[0]?.autor?.username : 'No Existe'}</b></Typography>
                                </Grid>



                                {
                                    user.rol === 'admin' &&
                                    <Grid style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                                        <MdDeleteForever onClick={() => borrarPost(tema._id, tema2._id)} style={{ color: 'white', fontSize: '25px' }} />
                                        <FaEdit style={{ color: 'white', fontSize: '25px' }} />
                                    </Grid>
                                }

                            </Link>
                        ))
                        :
                        <Typography style={{ color: 'white', fontStyle: 'italic', fontSize: '20px' }}>No existen posts creados en el tema</Typography>}
                </Grid>



                {
                    abrirCrear &&

                    <Grid style={modalStyle} >
                        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '15px', backgroundColor: 'grey', width: '50%', padding: '15px', borderRadius: '5px' }}>
                            <IoMdCloseCircle onClick={() => setAbrirCrear(!abrirCrear)} />

                            <Grid style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                <label style={{ color: 'white' }} htmlFor="titulo">Título:</label>
                                <input
                                    type="text"
                                    id="titulo"
                                    name="titulo"
                                    value={nuevoPost.titulo}
                                    onChange={(e) => setNuevoPost({ ...nuevoPost, idUsuario: user._id, titulo: e.target.value })}
                                    required
                                />
                            </Grid>

                            <Grid style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                <label style={{ color: 'white' }} htmlFor="contenido">contenido:</label>
                                <textarea
                                    id="contenido"
                                    name="contenido"
                                    value={nuevoPost.contenido}
                                    onChange={(e) => setNuevoPost({ ...nuevoPost, idUsuario: user._id, contenido: e.target.value })}
                                    required
                                />
                            </Grid>
                            <Button onClick={() => { handleCreatePost(); setAbrirCrear(!abrirCrear) }}>Crear</Button>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Layout>

    )
}

export default Tema