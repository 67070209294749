import React, { useState, useEffect } from 'react';
import Layout from '../layout/Layout';
import { Grid, Typography } from '@mui/material';
import { FaRankingStar } from 'react-icons/fa6';
import { GrHomeRounded } from 'react-icons/gr';
import './rankings.css';
import TablaResets from './TablaResets';
import TablaSDLF from './TablaSDLF';
import TablaPK from './TablaPK';
import TablaGuilds from './TablaGuilds';
import TablaOnline from './TablaOnline';
import InfoRanking from './InfoRanking';
import config from '../../config';
import { Helmet } from 'react-helmet';

const menuOptions = {
  online: {
    label: 'Online',
    url: `${config.apiBaseUrl}/rankings/online`,
    component: TablaOnline,
  },
  resets: {
    label: 'Resets',
    url: `${config.apiBaseUrl}/rankings/resets`,
    component: TablaResets,
  },
  sdlf: {
    label: 'Salón de la fama',
    url: `${config.apiBaseUrl}/rankings/sdlf`,
    component: TablaSDLF,
  },
  pk: {
    label: 'Phonomanias',
    url: `${config.apiBaseUrl}/rankings/pk`,
    component: TablaPK,
  },
  guilds: {
    label: 'Guilds',
    url: `${config.apiBaseUrl}/rankings/guilds`,
    component: TablaGuilds,
  },
};

function Rankings(props) {
  const [activeItem, setActiveItem] = useState('online');
  const [jugadores, setJugadores] = useState([]);
  const [nextUpdateTimes, setNextUpdateTimes] = useState({
    online: 5,
    resets: 30,
    sdlf: 30,
    pk: 30,
    guilds: 30,
  });

  const fetchData = async () => {
    try {
      const response = await fetch(menuOptions[activeItem].url);
      const data = await response.json();
      setJugadores(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchUpdateTimes = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/cuantofalta`);
      if (response.ok) {
        const data = await response.json();
        setNextUpdateTimes(data);
      } else {
        console.error('Error fetching update times:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching update times:', error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchUpdateTimes();

    const interval = setInterval(() => {
      fetchData();
      fetchUpdateTimes();
    }, 60000); // Actualizar cada 60 segundos

    return () => clearInterval(interval);
  }, [activeItem]);

  const handleMenuItemClick = (menuItem) => {
    setActiveItem(menuItem);
  };

  return (
    <Layout tyExpanded={props.tyExpanded} setExpanded={props.setExpanded} isMobile={props.isMobile}>
      <Helmet>
        <title>Mu Serendipia | Rankings</title>
        <meta name="description" content="Consulta los rankings de Mu Serendipia. Los mejores jugadores Online, Resets, Salón de la fama, Phonomanias y Guilds." />
        <meta name="keywords" content="mu serendipia, rankings, mejores jugadores, online, resets, salón de la fama, phonomanias, guilds, mu online, servidor privado, juego, mmorpg" />
      </Helmet>
      <Grid style={{ display: 'flex', flexDirection: 'column', gap: '35px', padding: props.isMobile ? '2rem 1rem' : '2rem 5.8rem' }}>

        <div className="usersettings-navbar">
          <Grid>
            <Grid style={{ display: 'flex', padding: '5px 5px', backgroundColor: '#2a2e38', borderRadius: '5px', gap: '15px', width: props.isMobile && '100%' }} className={'menu-rankings'}>
              {Object.keys(menuOptions).map((menuItem) => (
                <Grid key={menuItem} style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '10px' }} className={activeItem === menuItem ? "item-config-active" : "item-config"} onClick={() => handleMenuItemClick(menuItem)}>
                  {/* <GrHomeRounded /> */}
                  <Typography >{menuOptions[menuItem].label}</Typography>
                </Grid>

              ))}
            </Grid>

          </Grid>

        </div>
        <Grid style={{ display: 'flex', flexDirection: props.isMobile && 'column', alignItems: 'flex-start', gap: '10px', justifyContent: 'space-between' }}>

          {jugadores.length > 0 && React.createElement(menuOptions[activeItem].component, { jugadores })}
          <InfoRanking isMobile={props.isMobile} activeItem={activeItem} nextUpdateTime={nextUpdateTimes} />

        </Grid>
      </Grid>
    </Layout>
  );
}

export default Rankings;
