import { Grid, Typography, Button, Box, LinearProgress, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import sm from './sm.png';
import mg from './mg.png';
import elf from './elf.png';
import dl from './dl.png';
import bk from './bk.jpg';
import './Razasection.css'
const Razasection = (props) => {
    // Datos de los campeones
    const champions = [
        {
            name: 'Soul Master',
            description: 'Garen es un tanque con fuerte defensa...',
            image: sm,
            stats: [
                { name: 'Ataque', value: 75, color: '#FF4D4D' },
                { name: 'Defensa', value: 60, color: '#FF9500' },
                { name: 'Asistencia', value: 50, color: '#3D7FFF' },
                { name: 'Alcance', value: 30, color: '#4DFF4D' },
            ],
        },
        {
            name: 'Blade Knight',
            description: 'Ashe es una tiradora con ataques de largo alcance...',
            image: bk,
            stats: [
                { name: 'Ataque', value: 85, color: '#FF4D4D' },
                { name: 'Defensa', value: 65, color: '#FF9500' },
                { name: 'Asistencia', value: 40, color: '#3D7FFF' },
                { name: 'Alcance', value: 25, color: '#4DFF4D' },
            ],
        },
        {
            name: 'Muse Elf',
            description: 'Lux es una maga con habilidades de control de masas...',
            image: elf,
            stats: [
                { name: 'Ataque', value: 60, color: '#FF4D4D' },
                { name: 'Defensa', value: 55, color: '#FF9500' },
                { name: 'Asistencia', value: 80, color: '#3D7FFF' },
                { name: 'Alcance', value: 75, color: '#4DFF4D' },
            ],
        },
        {
            name: 'Dark Lord',
            description: 'Ahri es una maga asesina con habilidades de gran movilidad...',
            image: dl,
            stats: [
                { name: 'Ataque', value: 70, color: '#FF4D4D' },
                { name: 'Defensa', value: 60, color: '#FF9500' },
                { name: 'Asistencia', value: 40, color: '#3D7FFF' },
                { name: 'Alcance', value: 50, color: '#4DFF4D' },
            ],
        },
        {
            name: 'Magic Gladiator',
            description: 'Ahri es una maga asesina con habilidades de gran movilidad...',
            image: mg,
            stats: [
                { name: 'Ataque', value: 80, color: '#FF4D4D' },
                { name: 'Defensa', value: 70, color: '#FF9500' },
                { name: 'Asistencia', value: 45, color: '#3D7FFF' },
                { name: 'Alcance', value: 35, color: '#4DFF4D' },
            ],
        },
    ];

    const [selectedChampion, setSelectedChampion] = useState(champions[0]);

    const characters = [
        {
            name: 'Soul Master',
            stats: [
                { name: 'Ataque', value: 75, color: '#FF4D4D' },
                { name: 'Defensa', value: 60, color: '#FF9500' },
                { name: 'Asistencia', value: 50, color: '#3D7FFF' },
                { name: 'Alcance', value: 30, color: '#4DFF4D' },
            ],
        },
        {
            name: 'Blade Knight',
            stats: [
                { name: 'Ataque', value: 85, color: '#FF4D4D' },
                { name: 'Defensa', value: 65, color: '#FF9500' },
                { name: 'Asistencia', value: 40, color: '#3D7FFF' },
                { name: 'Alcance', value: 25, color: '#4DFF4D' },
            ],
        },
        // Agrega más personajes según sea necesario
    ];
    const [hoveredStat, setHoveredStat] = useState(null);
    const referencias = [
        { name: 'Ataque', color: '#FF4D4D' },
        { name: 'Alcance', color: '#FF9500' },
        { name: 'Defensa', color: '#3D7FFF' },
        { name: 'Asistencia', color: '#4DFF4D' },
    ];

    const classes = [
        { label: 'SM', color: '#3D7FFF' },
        { label: 'BK', color: '#FF4D4D' },
        { label: 'FE', color: '#FF9500' },
        { label: 'MG', color: '#FFE600' },
        { label: 'DL', color: '#A64D79' },

    ];

    const damageData = [
        [30, 20, 50, 40, 60,],
        [60, 50, 30, 70, 40,],
        [10, 20, 80, 40, 60,],
        [30, 20, 50, 40, 60,],
        [60, 50, 30, 70, 40,],


    ];
    return (
        <div
            style={styles.wrapper}
        >
            <div style={styles.backgroundContainer} />
            <Grid style={{ display: 'flex', flexDirection: props.isMobile ? ' column' : 'row',alignItems: 'center', width: '100%', justifyContent: 'space-between', height: !props.isMobile && '100%' }}>
                <Grid style={{ display: 'flex', flexDirection: 'column', gap: '10px', zIndex: 999, justifyContent: 'center', width: props.isMobile && '80%' }}>

                    <Grid style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                        <Typography style={{ color: 'white', textAlign: 'center', fontFamily: 'Beaufort' }}>Conoce a algunas de</Typography>
                        <Typography style={{ color: 'white', textAlign: 'center', fontSize: '60px', fontFamily: 'Beaufort' }}>LAS RAZAS</Typography>
                    </Grid>

                    <Grid style={{ display: 'flex', gap: '10px' }}>
                        {champions.map((champion, index) => (
                            <div
                                key={index}
                                style={{
                                    ...styles.championCard,
                                    opacity: selectedChampion.name === champion.name ? 1 : 0.5,
                                    filter: selectedChampion.name === champion.name ? 'brightness(1.2)' : 'grayscale(100%)',
                                }}
                                onMouseEnter={() => setSelectedChampion(champion)}
                            >
                                <img src={champion.image} alt={champion.name} style={styles.championImage} className='championImage'/>
                                <h3 style={styles.championName}>{champion.name}</h3>
                            </div>
                        ))}
                    </Grid>


                    {/* <Box sx={{ borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <Typography variant="h4" color="#fff" gutterBottom style={{ fontFamily: 'Beaufort' }}>
                            Daño Clase ⚔️ Clase
                        </Typography>
                        
                        <Box display="flex" mt={2} mb={2} style={{ width: '80%', justifyContent: 'flex-end' }}>
                            {classes.map((clase, index) => (
                                <Box key={index} sx={{ backgroundColor: clase.color, padding: '5px 10px', borderRadius: '5px', mx: 0.5 }}>
                                    <Typography variant="body2" color="#fff" fontWeight="bold">
                                        {clase.label}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                        {damageData.map((row, rowIndex) => (
                            <Box key={rowIndex} display="flex" alignItems="center" mb={1} style={{ width: '80%', gap: '10px' }}>
                                
                                <Typography variant="body1" color="#fff" sx={{ width: '30px', borderRadius: '5px', backgroundColor: classes[rowIndex]?.color, }}>
                                    {classes[rowIndex]?.label}
                                </Typography>

                                
                                <Box display="flex" flexGrow={1} height="20px">
                                    {row.map((damage, index) => (
                                        <Tooltip key={index} title={`${classes[rowIndex]?.label} ⚔️ ${classes[index]?.label}: +${damage}%`} arrow>
                                            <Box
                                                sx={{
                                                    flexGrow: damage, // Proporcional al valor del daño
                                                    backgroundColor: classes[index]?.color,
                                                    height: '100%',
                                                }}
                                            />
                                        </Tooltip>
                                    ))}
                                </Box>
                            </Box>
                        ))}
                    </Box> */}
                </Grid>

                <div style={styles.championDetails} className='championDetails'>
                    <img src={selectedChampion.image} style={{ borderRadius: '50%', width: props.isMobile && '50%' }} />
                    <h2 style={styles.detailsTitle}>{selectedChampion.name}</h2>
                    {/*
                    <Box sx={{ padding: '20px', borderRadius: '8px', width: '100%' }}>
                        <Box sx={{ width: '80%', padding: '10px', borderRadius: '8px' }}>
                            {selectedChampion.stats.map((stat, index) => (
                                <Box
                                    key={index}
                                    display="flex"
                                    alignItems="center"
                                    mb={1}
                                    onMouseEnter={() => setHoveredStat(stat.name)}
                                    onMouseLeave={() => setHoveredStat(null)}
                                    sx={{ position: 'relative' }}
                                >
                                   
                                    {hoveredStat === stat.name && (

                                        <Tooltip key={index} title={hoveredStat} arrow>
                                            <Box
                                                sx={{
                                                    backgroundColor: 'white',
                                                    color: 'black',
                                                    height: '100%',
                                                }}
                                            />
                                        </Tooltip>
                                    )}

                                    <LinearProgress
                                        variant="determinate"
                                        value={stat.value}
                                        sx={{
                                            flexGrow: 1,
                                            ml: 1,
                                            height: '10px',
                                            backgroundColor: '#333',
                                            '& .MuiLinearProgress-bar': { backgroundColor: stat.color },
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>

                        <Box display="flex" justifyContent="center" gap={2}>
                            {referencias.map((stat, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        backgroundColor: stat.color,
                                        padding: '5px 10px',
                                        borderRadius: '5px',
                                        display: 'inline-block',
                                    }}
                                >
                                    <Typography variant="body2" color="#fff" fontWeight="bold">
                                        {stat.name}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    */}
                </div>
            </Grid>
        </div>
    );
};

// Estilos en línea
const styles = {
    wrapper: {
        position: 'relative',
        height: '673px',
        overflow: 'hidden',
    },
    backgroundContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url('https://uploadcdn.webzen.com/Files/MU/guide/03.jpg')`, // Imagen de fondo
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        filter: 'blur(8px)', // Aplicar blur al fondo
        zIndex: 1, // Asegurar que el fondo esté detrás del contenido
    },
    championsSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '2rem',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        transition: 'background-image 0.5s ease-in-out',
        position: 'relative',
        height: '673px',
        color: 'white',
    },
    championCards: {
        display: 'flex',
        width: '60%',
        gap: '1rem',
    },
    championCard: {
        width: '150px',
        height: '200px',
        cursor: 'pointer',
        textAlign: 'center',
        transition: 'transform 0.3s ease, filter 0.3s ease',
    },
    championImage: {
        width: '90px',
        height: '90px',
        objectFit: 'cover',
        borderRadius: '50%',
        '@media (max-width: 768px)': {
            width: '30%',
        }
    },
    championName: {
        marginTop: '0.5rem',
        fontWeight: 'bold',
        color: 'white',
    },
    championDetails: {
        width: '40%',
        padding: '1rem',
        position: 'relative',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

    },
    largeCircularImageContainer: {
        position: 'absolute',
        top: '-150px',  // Ajuste para que la imagen esté en una posición destacada
        right: '-50px',
        width: '400px',  // Tamaño grande similar al ejemplo
        height: '400px',
        borderRadius: '50%',
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
    },
    detailsTitle: {
        fontSize: '2rem',
        marginBottom: '1rem',
        zIndex: 2, // Para asegurar que el texto esté por encima de la imagen circular
        color: 'white',
        textAlign: 'center',
        '@media (max-width: 768px)': {
            fontSize: '.5rem',
            
        }
    },
    buttonStyle: {
        marginTop: '20px',
        backgroundColor: '#d4af37',
        color: '#fff',
        fontFamily: 'Beaufort',
        fontSize: '1rem',
        padding: '10px 20px',
        borderRadius: '5px',
        textTransform: 'uppercase',
        zIndex: 2,
    },
};

export default Razasection;
