import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./Auth.css";
import axios from 'axios'
import { IoMdCloseCircleOutline } from "react-icons/io";
import QRCode from 'react-qr-code';
import config from '../../config';
import { Alert } from "@mui/material";
import ReactGA from 'react-ga4';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
export default function Auth({ isMobile, closePopup, typeDefault }) {
  const [type, setType] = useState(typeDefault);
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [errorUserName, setErrorUserName] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(null);
  const [errorEmail, setErrorEmail] = useState(null);
  const [CodeConfirm, setCodeConfirm] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [secretKey, setSecretKey] = useState("");
  const createSecret = (secretLength = 16) => {
    const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';
    let secret = '';
    for (let i = 0; i < secretLength; i++) {
      secret += validChars.charAt(Math.floor(Math.random() * validChars.length));
    }
    return secret;
  };
  const handleChange = (e) => {

    const { name, value } = e.target;
    
    // Limitar a 10 caracteres solo si el nombre es 'password'
    if (name === 'password' && value.length > 10) {
      return; // No permitir más de 10 caracteres
    }
    
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const onChangeConfirmPassword = (e) => {

    const { name, value } = e.target;

    if (name === 'password' && value.length > 10) {
      return; // No permitir más de 10 caracteres
    }

    setFormData(prevState => ({
      ...prevState,
      confirmPassword: value
    }));
  };
  const validarCampos = () => {
    const errores = {};
    if (!formData.email || !/^\S+@\S+\.\S+$/.test(formData.email)) {
      errores.email = 'El email es inválido';
    }
    if (!formData.username || !/^[a-zA-Z0-9]{4,10}$/.test(formData.username)) {
      errores.username = 'La cuenta debe tener entre 4 y 10 caracteres alfanuméricos';
    }
    if (!formData.password || !/^[a-zA-Z0-9~!#$%&*-_+=|:.]{6,10}$/.test(formData.password)) {
      errores.password = 'La contraseña debe tener entre 6 y 10 caracteres y solo puede contener números, letras y los siguientes caracteres: ~ ! # $ % & * - _ + = | : .';
    }
    if (formData.password !== formData.confirmPassword) {
      errores.confirmPassword = 'Las contraseñas no coinciden';
    }
    return errores;
  };
  const [loginError, setLoginError] = useState('');

  const handleNextStep = async () => {
    const errores = validarCampos();
    if (Object.keys(errores).length > 0) {
      setErrorUserName(errores.username || '');
      setErrorEmail(errores.email || '');
      setErrorPassword(errores.password || '');
      setErrorConfirmPassword(errores.confirmPassword || '');
      return;
    }
    try {
      const response = await fetch(`${config.apiBaseUrl}/verificar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          account: formData.username
        })
      });
      const data = await response.json();
      if (data.exists) {
        setErrorUserName('La cuenta ya existe');
        return;
      }
      try {
        const secretKey = createSecret();
        setSecretKey(secretKey);
        const qrCodeData = `otpauth://totp/MuSerendipia:${formData.username}?secret=${secretKey}&issuer=MuSerendipia`;
        setQrCodeUrl(qrCodeData);
        setStep(1);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
    }
  };

  const handleLogin = async () => {
    if (!formData.username || !formData.password) {
      return;
    }
    try {
      const response = await axios.post(`${config.apiBaseUrl}/login`, {
        account: formData.username,
        password: formData.password
      });
      if (response.data.success) {
        const token = response.data.token;
        window.localStorage.setItem("token", token);
        window.localStorage.setItem("account", formData.username);
        window.location.href = "/";
      } else {
        setLoginError('El usuario no existe o la contraseña es incorrecta');
      }
    } catch (error) {
      setLoginError('Error al iniciar sesión');
    }
  };

  // Agrega el listener de teclado para detectar "Enter"
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleLogin();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [formData]);
  const [verificationStatus, setVerificationStatus] = useState("");
  const handleVerifyCode = async (code) => {
    const isNumericSixDigits = /^\d{6}$/.test(code);
    if (!isNumericSixDigits) {
      return;
    }
    try {
      const response = await axios.post(`${config.apiBaseUrl}/verificarqr`, {
        secretKey: secretKey,
        code: code
      });
      if (response.data.isValid) {
        if (type === 1) {
          handleRegister();
        } else if (type === 2) {
          handleRecovery();
        }
      } else {
        setVerificationStatus("Código incorrecto");
      }
    } catch (error) {
      setVerificationStatus("Error en la verificación");
    }
  };

  const handleRegister = async () => {
    try {
      let requestData = {};
      requestData = {
        email: formData.email,
        account: formData.username,
        password: formData.password,
        authenticator: secretKey
      };
      const response = await axios.post(`${config.apiBaseUrl}/registronuevo`, requestData);
      if (response.data === true) {
        ReactGA.event({
          category: 'User',
          action: 'Created an Account'
        });
        setStep(2);
      } else {
        setStep(3);
      }
    } catch (error) {
      setStep(3);
    }
  };
  const [CodeConfirmInput, setCodeConfirmInput] = useState(0);

  function onChangeCodeConfirm(e) {
    setCodeConfirmInput(e);
  }

  //RECOVERY
  const [errores, setErrores] = useState({});
  const [email, setEmail] = useState(''); // Nuevo estado para almacenar el email
  const [verificationCode, setVerificationCode] = useState(''); // Estado para el código de recuperación
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const handleRecovery = async () => {
    if (step === 0) {
      // Validar el formato de la cuenta
      if (!formData.username || !/^[a-zA-Z0-9]{4,10}$/.test(formData.username)) {
        setErrores({ username: 'Cuenta no válida' });
        return;
      }

      try {
        // Llamar al servicio de recuperación
        const response = await fetch(`${config.apiBaseUrl}/recuperar`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            account: formData.username,
            step: 1
          })
        });

        if (!response.ok) {
          throw new Error('Error al recuperar la cuenta');
        }

        const data = await response.json();

        // Verificar la respuesta del servicio
        if (data.success) {
          console.log('Avanzar al paso 1');
          setEmail(data.email); // Guardar el email recibido
          setStep(1);
        } else {
          setErrorUserName('No se pudo recuperar la cuenta');
        }
      } catch (error) {
        setErrorUserName('No se pudo recuperar la cuenta');
      }
    } else if (step === 1) {
      // Validar el código de recuperación
      if (!/^[a-zA-Z0-9]{10}$/.test(verificationCode)) {
        setErrores({ verificationCode: 'Código no válido. Debe ser alfanumérico de 10 caracteres.' });
        return;
      }

      try {
        // Enviar el código de recuperación al servidor
        const response = await fetch(`${config.apiBaseUrl}/recuperar`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            account: formData.username,
            step: 2,
            recovery_code: verificationCode
          })
        });

        if (!response.ok) {
          throw new Error('Error al verificar el código');
        }

        const data = await response.json();

        // Verificar la respuesta del servidor
        if (data.valid) {
          console.log('Avanzar al paso 2');
          setStep(2);
        } else {
          setErrores({ verificationCode: 'Código incorrecto. Inténtalo de nuevo.' });
        }
      } catch (error) {
        setErrores({ verificationCode: 'Error al verificar el código' });
      }
    } else if (step === 2) {
      // Validar la nueva contraseña
      if (!newPassword || newPassword !== repeatPassword) {
        setErrores({ newPassword: 'Las contraseñas no coinciden.' });
        return;
      }

      // Realizar validaciones adicionales de la contraseña si es necesario
      if (!/^[a-zA-Z0-9]{6,10}$/.test(newPassword)) {
        setErrores({ newPassword: 'La contraseña debe contener entre 6 y 10 caracteres alfanuméricos.' });
        return;
      }

      try {
        const secretKey = createSecret();
        setSecretKey(secretKey);
        const qrCodeData = `otpauth://totp/MuSerendipia:${formData.username}?secret=${secretKey}&issuer=MuSerendipia`;
        setQrCodeUrl(qrCodeData);
        setStep(3);  // Avanzar al siguiente paso
      } catch (error) {
        console.log(error);
      }

    } else if (step === 3) {

      const response = await fetch(`${config.apiBaseUrl}/recuperar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          account: formData.username,
          step: 3,
          new_password: newPassword,
          auth_secret: secretKey,
          recovery_code: verificationCode
        })
      });

      if (!response.ok) {
        throw new Error('Error al restablecer la cuenta');
      }

      const data = await response.json();

      if (data.success) {
        console.log('Cuenta restablecida exitosamente');
        setStep(4); // Avanzar al paso 4 si todo sale bien
      } else {
        console.log('No se pudo restablecer la cuenta');
        setStep(5); // Avanzar al paso 5 si ocurre algún error
      }

    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  function getType() {
    if (type === 0) {
      return (
        <div className="auth-content">
          {loginError && <Alert severity="error">{loginError}</Alert>}
          <div className="auth-content-input">
            <p style={{ fontSize: '12px', fontFamily: 'inter' }}>Cuenta*</p>
            <input
              name="username"
              id="identifierId"
              value={formData.username}
              onChange={handleChange}
              type="text"
            />
          </div>
          <div className="auth-content-input">
            <p style={{ fontSize: '12px', fontFamily: 'inter' }}>Contraseña*</p>
            <div style={{ position: 'relative', borderRadius: '5px' }}>
              <input
                name="password"
                value={formData.password}
                onChange={handleChange}
                type={showPassword ? 'text' : 'password'}
                style={{ paddingRight: '30px', width: '100%' }} // Espacio para el icono
              />
              <span
                onClick={togglePasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                }}
              >
                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              </span>
            </div>
            <p style={{ fontSize: '12px', fontFamily: 'inter', textAlign: 'right' }}>
              {formData.password.length}/10 caracteres
            </p>
          </div>
          <a onClick={() => setType(2)} style={{ padding: 0 }}>
            <p
              className="parrafo-auth"
              style={{
                fontSize: "12px",
                textAlign: "end",
                color: "#EB0400",
                cursor: "pointer",
              }}
            >
              ¿Tienes problemas para iniciar sesión?
            </p>
          </a>
          <button onClick={handleLogin} className="auth-button-login">
            Iniciar Sesión
          </button>
        </div>
      );
    }
    if (type === 1 && step === 0) {
      return (
        <div>
          {!CodeConfirm ? (
            <div className="auth-content">
              <div className="auth-content-input">
                <p style={{ fontSize: '12px', fontFamily: 'inter' }}>Email*</p>
                <input
                  className={errorEmail && "input-error"}
                  value={formData.email}
                  onChange={handleChange}
                  name="email"
                  type="text"
                />
                {errorEmail && <p style={{ fontSize: "10px", color: "rgb(228, 122, 122)", marginTop: "5px" }}>{errorEmail}</p>}
              </div>
              <div className="auth-content-input">
                <p style={{ fontSize: '12px', fontFamily: 'inter' }}>Cuenta*</p>
                <input
                  className={errorUserName && "input-error"}
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  type="text"
                />
                {errorUserName && <p style={{ fontSize: "12px", color: "rgb(228, 122, 122)", marginTop: "5px", fontFamily: 'inter' }}>{errorUserName}</p>}
              </div>
              <div className="auth-content-input">
                <p style={{ fontSize: '12px', fontFamily: 'inter' }}>Contraseña*</p>
                <div style={{ position: 'relative', width:'100%' }}>
                  <input
                    className={errorPassword && "input-error"}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    style={{width:'100%'}}
                    type={showPassword ? 'text' : 'password'}

                  />
                  <span
                    onClick={togglePasswordVisibility}
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer',
                    }}
                  >
                    {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </span>
                </div>
                <p style={{ fontSize: '12px', fontFamily: 'inter', textAlign: 'right' }}>
                  {formData.password.length}/10 caracteres
                </p>
                {errorPassword && <p style={{ fontSize: "10px", color: "rgb(228, 122, 122)", marginTop: "5px" }}>{errorPassword}</p>}
              </div>
              <div className="auth-content-input">
                <p style={{ fontSize: '12px', fontFamily: 'inter' }}>Repite la contraseña*</p>
                <input
                  className={errorConfirmPassword && "input-error"}
                  name="password"
                  onChange={(e) => onChangeConfirmPassword(e)}
                  type="password"
                />
                {errorConfirmPassword && <p style={{ fontSize: "10px", color: "rgb(228, 122, 122)", marginTop: "5px" }}>{errorConfirmPassword}</p>}
              </div>
              <div className="auth-text">
                <input type="checkbox" style={{ width: '5%', border: 0 }} />
                <p className="" style={{ fontSize: "11px" }}>
                  He leído las {" "}<a style={{ color: "#EB0400", cursor: "pointer" }}>Reglas</a>{" "}
                  y estoy de acuerdo con ellas.
                </p>
              </div>
              <button onClick={handleNextStep} className="auth-button-login">
                Siguiente paso
              </button>
            </div>
          ) : (
            <div className="auth-content">
              <div className="auth-content-input">
                <p>Revisa tu correo</p>
                <input onChange={(e) => onChangeCodeConfirm(e.target.value)} type="number" />
                <button style={{ width: "100%" }} className="auth-button-login">Enviar código</button>
              </div>
            </div>
          )}
        </div>
      );
    }
    if (type === 1 && step === 1) {
      return (
        <div className="auth-content">
          <h4>Escanea el código QR</h4>
          <div className="auth-content-input" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: '#21252d', padding: '5px', borderRadius: '5px' }}>
            <div style={{ marginRight: !isMobile && '10px' }}>
              <QRCode value={qrCodeUrl} size={130} />
            </div>
            <div style={{ width: '70%' }}>
              <p style={{ fontSize: '12px', fontFamily: 'Inter' }}>
                Para verificar tu cuenta, necesitarás descargar <a href="https://support.google.com/accounts/answer/1066447?hl=es" target="_blank" style={{ textDecoration: 'none', color: '#cd1009' }}>Google Authenticator</a> desde la tienda de aplicaciones de tu dispositivo. Luego, escanea el código que aparece en la pantalla e ingresa el código proporcionado.
              </p>
            </div>
          </div>
          <h4>Ingresa el código de verificación</h4>
          <div className="auth-content-input">
            <input
              style={{ width: '100%' }}
              type="text"
              pattern="\d{6}"
              maxLength={6}
              onChange={(e) => handleVerifyCode(e.target.value)}
            />
            <p>{verificationStatus}</p>
          </div>
          <button disabled={verificationStatus !== "Correcto"} onClick={handleRegister} className="auth-button-login">
            Crea tu cuenta
          </button>
        </div>
      );
    }
    if (type === 1 && step === 2) {
      return (
        <div className="auth-content">
          <div style={{ textAlign: "left", marginTop: "30px" }} className="auth-text">
            <h3>¡Bienvenido a Mu Serendipia!</h3>
          </div>
          <div className="auth-changelook-container">
            <img
              style={{ width: "150px" }}
              src="https://res.cloudinary.com/pinkker/image/upload/v1679518811/pinkker-ups_iqucmd.png"
              alt="Pinkker"
            />
          </div>
          <button style={{ marginTop: "50px" }} className="auth-button-login" onClick={() => closePopup()}>
            Cerrar
          </button>
        </div>
      );
    }
    if (type === 1 && step === 3) {
      return (
        <div className="auth-content">
          <h3>¡Lo sentimos!</h3>
          <div style={{ textAlign: "left", marginTop: "30px" }} className="auth-text">
            <p>Algo ha salido mal y no hemos podido crear la cuenta. Por favor, inténtalo nuevamente más tarde.</p>
          </div>
          <button style={{ marginTop: "50px" }} className="auth-button-login" onClick={() => closePopup()}>
            Cerrar
          </button>
        </div>
      );
    }

    if (type === 2) {
      if (step === 0) {
        return (
          <div className="auth-content">
            <div className="auth-content-input">
              <p>Ingresa tu cuenta</p>
              <input
                id="username"
                type="text"
                value={formData.username}
                onChange={(e) => setFormData({ ...formData, username: e.target.value })}
              />
              {errores.username && <p className="error-message">{errores.username}</p>}
            </div>
            <button onClick={handleRecovery} className="auth-button-login">
              Siguiente paso
            </button>
          </div>
        );
      } else if (step === 1) {
        return (
          <div className="auth-content">
            <p>Hemos enviado un código de verificación al email: {email}</p>
            <p>El código es válido durante 24 horas. Si no lo ves en tu bandeja de entrada, revisa la carpeta de spam o correo no deseado.</p>
            <div className="auth-content-input">
              <p>Ingresa el código de verificación</p>
              <input
                id="verificationCode"
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
              {errores.verificationCode && <p className="error-message">{errores.verificationCode}</p>}
            </div>
            <button onClick={handleRecovery} className="auth-button-login">
              Siguiente paso
            </button>
          </div>
        );
      } else if (step === 2) {
        return (
          <div className="auth-content">
            <div className="auth-content-input">
              <p>Ingresa una nueva contraseña</p>
              <input
                id="newPassword"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {errores.newPassword && <p className="error-message">{errores.newPassword}</p>}
            </div>
            <div className="auth-content-input">
              <p>Repite la nueva contraseña</p>
              <input
                id="repeatPassword"
                type="password"
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
              />
              {repeatPassword && newPassword !== repeatPassword && <p className="error-message">Las contraseñas no coinciden.</p>}
            </div>
            <button onClick={handleRecovery} className="auth-button-login">
              Siguiente paso
            </button>
          </div>
        );
      } else if (step === 3) {
        return (
          <div className="auth-content">
            <h4>Escanea el código QR</h4>
            <div className="auth-content-input" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: '#21252d', padding: '5px', borderRadius: '5px' }}>
              <div style={{ marginRight: !isMobile && '10px' }}>
                <QRCode value={qrCodeUrl} size={130} />
              </div>
              <div style={{ width: '70%' }}>
                <p style={{ fontSize: '12px', fontFamily: 'Inter' }}>
                  Para verificar tu cuenta, necesitarás descargar <a href="https://support.google.com/accounts/answer/1066447?hl=es" target="_blank" style={{ textDecoration: 'none', color: '#cd1009' }}>Google Authenticator</a> desde la tienda de aplicaciones de tu dispositivo. Luego, escanea el código que aparece en la pantalla e ingresa el código proporcionado.
                </p>
              </div>
            </div>
            <h4>Ingresa el código de verificación</h4>
            <div className="auth-content-input">
              <input
                style={{ width: '100%' }}
                type="text"
                pattern="\d{6}"
                maxLength={6}
                onChange={(e) => handleVerifyCode(e.target.value)}
              />
              <p>{verificationStatus}</p>
            </div>
            <button disabled={verificationStatus !== "Correcto"} onClick={handleRecovery} className="auth-button-login">
              Recupera tu cuenta
            </button>
          </div>
        );
      } else if (step === 4) {
        return (
          <div className="auth-content">
            <div style={{ textAlign: "left", marginTop: "30px" }} className="auth-text">
              <h3>¡Cuenta restablecida exitosamente!</h3>
            </div>
            <div className="auth-changelook-container">
              <img
                style={{ width: "150px" }}
                src="https://res.cloudinary.com/pinkker/image/upload/v1679518811/pinkker-ups_iqucmd.png"
                alt="Pinkker"
              />
            </div>
            <button style={{ marginTop: "50px" }} className="auth-button-login" onClick={() => closePopup()}>
              Cerrar
            </button>
          </div>
        );
      } else if (step === 5) {
        return (
          <div className="auth-content">
            <h3>¡Lo sentimos!</h3>
            <div style={{ textAlign: "left", marginTop: "30px" }} className="auth-text">
              <p>Algo ha salido mal y no hemos podido restablecer la cuenta. Por favor, inténtalo nuevamente más tarde.</p>
            </div>
            <button style={{ marginTop: "50px" }} className="auth-button-login" onClick={() => closePopup()}>
              Cerrar
            </button>
          </div>
        );
      }
    }
  }

  return (
    <div className="auth-body-container">
      <div className={"auth-body"}>
        <div className={"auth-info"}>
          <div style={{ width: "100%" }}>
            <div
              style={{
                color: "#ededed",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: type === 0 ? "300px" : "500px",
              }}
            >
            </div>
          </div>
        </div>
        <div
          className={"auth-container"}
          style={{ width: isMobile && '100% !important' }}
        >
          <div style={{ width: "100%" }}>
            <div className="auth-title">
              <div
                onClick={() => setType(1)}
                className={
                  type === 1 ? "auth-title-card active" : "auth-title-card"
                }
              >
                <h6 style={{ color: "white", fontFamily: 'Inter', fontWeight: 'bold' }}>Registrarse</h6>
              </div>
              <div
                onClick={() => setType(0)}
                className={
                  type === 0 ? "auth-title-card active" : "auth-title-card"
                }
              >
                <h6 style={{ color: "#ededed", fontFamily: 'Inter', fontWeight: 'bold' }}>Ingresar</h6>
              </div>
            </div>
            {getType()}
          </div>
          <div className="auth-close">
            <button className="pinkker-button-more" onClick={closePopup}>
              <IoMdCloseCircleOutline style={{ fontSize: isMobile && "22px", color: 'white' }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
