import React, { useState } from 'react'
import Layout from '../layout/Layout'
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import './micuenta.css'
import sm from './sm.png';
import mg from './mg.png';
import elf from './elf.png';
import dl from './dl.png';
import bk from './bk.jpg';
import logoFooter from './logo.png'

function Micuenta(props) {
    const [open, setOpen] = useState(false);
    const [selectedImg, setSelectedImg] = useState('');
    const [selectedCharacter, setSelectedCharacter] = useState(null);

    const handleClickOpen = (imgSrc, characterData) => {
        setSelectedImg(imgSrc);
        setSelectedCharacter(characterData);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedImg('');
        setSelectedCharacter(null);
    };

    const characters = [
        {
            name: 'eldenguee',
            resets: '5 resets',
            level: 'Level 290',
            class: 'Blade Knight',
            imgSrc: sm,
            stats: {
                Fuerza: '',
                Agilidad: '',
                Vitalidad: '',
                Energía: '',
                Puntos: '',
                Guild: '',
                Muertes: '()',
                CostoPK: '',
                PuntosPK: '',
                Zen: '',
                PuntosDS: '0'
            }
        },
        {
            name: 'eldenguee',
            resets: '5 resets',
            level: 'Level 290',
            class: 'Blade Knight',
            imgSrc: bk,
            stats: {
                Fuerza: '',
                Agilidad: '',
                Vitalidad: '',
                Energía: '',
                Puntos: '',
                Guild: '',
                Muertes: '()',
                CostoPK: '',
                PuntosPK: '',
                Zen: '',
                PuntosDS: '0'
            }
        },
        {
            name: 'eldenguee',
            resets: '5 resets',
            level: 'Level 290',
            class: 'Blade Knight',
            imgSrc: dl,
            stats: {
                Fuerza: '',
                Agilidad: '',
                Vitalidad: '',
                Energía: '',
                Puntos: '',
                Guild: '',
                Muertes: '()',
                CostoPK: '',
                PuntosPK: '',
                Zen: '',
                PuntosDS: '0'
            }
        },
        {
            name: 'eldenguee',
            resets: '5 resets',
            level: 'Level 290',
            class: 'Blade Knight',
            imgSrc: elf,
            stats: {
                Fuerza: '',
                Agilidad: '',
                Vitalidad: '',
                Energía: '',
                Puntos: '',
                Guild: '',
                Muertes: '()',
                CostoPK: '',
                PuntosPK: '',
                Zen: '',
                PuntosDS: '0'
            }
        },
        {
            name: 'eldenguee',
            resets: '5 resets',
            level: 'Level 290',
            class: 'Blade Knight',
            imgSrc: mg,
            stats: {
                Fuerza: '',
                Agilidad: '',
                Vitalidad: '',
                Energía: '',
                Puntos: '',
                Guild: '',
                Muertes: '()',
                CostoPK: '',
                PuntosPK: '',
                Zen: '',
                PuntosDS: '0'
            }
        },
        {
            name: '--',
            resets: '5 resets',
            level: 'Level 290',
            class: 'Blade Knight',
            imgSrc: logoFooter,
            stats: {
                Fuerza: '',
                Agilidad: '',
                Vitalidad: '',
                Energía: '',
                Puntos: '',
                Guild: '',
                Muertes: '()',
                CostoPK: '',
                PuntosPK: '',
                Zen: '',
                PuntosDS: '0'
            }
        },
        // Add more characters as needed
    ];
    return (
        <Layout tyExpanded={props.tyExpanded} setExpanded={props.setExpanded} isMobile={props.isMobile}>
            <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 15, gap: '20px', justifyContent: 'center' }}>

                <Grid style={{ display: 'flex', flexDirection: 'column', padding: 5, borderRadius: '5px', borderColor: '1px solid rgb(42, 46, 56) ', width: '70%', justifyContent: 'center', margin: '0 auto', background: '#121418' }} >
                    <Grid style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: 5 }} className='infoOnline' >
                        <Typography style={{ color: 'white' }} >PERSONAJES E INFORMACIÓN</Typography>
                    </Grid>

                    <Grid style={{ display: 'flex', width: '100%', padding: 5, gap: '5px' }}>


                        {characters.map((character, index) => (
                            <Grid
                                key={index}
                                style={{ display: 'flex', flexDirection: 'column', gap: '5px', cursor: 'pointer' }}
                                className='img-cuenta'
                                onClick={() => handleClickOpen(character.imgSrc, character)}
                            >
                                <img src={character.imgSrc} alt='Character' style={{ borderRadius: '5px' }} />
                                <Typography style={{ color: 'white', textAlign: 'center' }}>{character.name}</Typography>
                            </Grid>
                        ))}




                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', border: '1px solid rgb(42, 46, 56)' }}>
                        <Grid style={{ padding: 5, borderRight: '1px solid rgb(42, 46, 56)', width: '15%' }}>
                            <Typography style={{ color: 'white' }}>E-mail:</Typography>
                        </Grid>
                        <Grid style={{ padding: 5 }}>
                            <Typography style={{ color: 'white' }}>elde****@gmail.com</Typography>
                        </Grid>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', border: '1px solid rgb(42, 46, 56)' }}>
                        <Grid style={{ padding: 5, borderRight: '1px solid rgb(42, 46, 56)', width: '15%' }}>
                            <Typography style={{ color: 'white' }}>Cuenta creada:</Typography>
                        </Grid>
                        <Grid style={{ padding: 5 }}>
                            <Typography style={{ color: 'white' }}>12/9/2024</Typography>
                        </Grid>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', border: '1px solid rgb(42, 46, 56)' }}>
                        <Grid style={{ padding: 5, borderRight: '1px solid rgb(42, 46, 56)', width: '15%' }}>
                            <Typography style={{ color: 'white' }}>Último acceso:</Typography>
                        </Grid>
                        <Grid style={{ padding: 5 }}>
                            <Typography style={{ color: 'white' }}>12/9/2024</Typography>
                        </Grid>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', backgroundColor: '#660000', border: '1px solid #330000' }}>
                        <Grid style={{ padding: 5 }}>
                            <Typography style={{ color: 'white' }}>Cuenta Baneada:</Typography>
                        </Grid>
                        <Grid style={{ padding: 5 }}>
                            <Typography style={{ color: 'white' }}>Cuenta Baneada hasta:</Typography>
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>

            <Dialog open={open} onClose={handleClose} maxWidth='lg' BackdropProps={{
                style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    backdropFilter: 'blur(10px)', // Apply blur effect
                    width: '110% !important',
                },
            }}>
                <Grid style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: 5, background: "#333333" }}  >
                    <Typography style={{ color: 'white' }} >INFORMACIÓN DE PERSONAJE</Typography>
                </Grid>
                <DialogContent style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#121418' }}>

                    <Grid item xs={4} style={{ paddingRight: '15px' }}>
                        <img src={selectedImg} alt='Character' style={{ width: '100%', borderRadius: '5px' }} />
                        <Typography style={{ color: 'white', textAlign: 'center', marginTop: '10px', fontSize: '24px' }}>
                            {selectedCharacter?.name}
                        </Typography>
                        <Typography style={{ color: 'rgb(155, 165, 180)', textAlign: 'center', marginTop: '5px' }}>
                            {selectedCharacter?.resets}/{selectedCharacter?.level}
                        </Typography>
                        <Typography style={{ color: 'rgb(155, 165, 180)', textAlign: 'center', marginTop: '5px' }}>
                            ({selectedCharacter?.class})
                        </Typography>
                    </Grid>


                    <Grid item xs={8} style={{ paddingLeft: '15px' }}>
                        {selectedCharacter && (
                            <Grid container spacing={2} style={{ padding: '10px', borderRadius: '5px', height: '100%' }}>
                                {/* Stat Row 1 */}
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Fuerza:</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Guild:</Typography>
                                </Grid>

                                {/* Stat Row 2 */}
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Agilidad:</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Muertes: </Typography>
                                </Grid>

                                {/* Stat Row 3 */}
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Vitalidad:</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Costo PK:</Typography>
                                </Grid>

                                {/* Stat Row 4 */}
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Energía:</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Puntos PK:</Typography>
                                </Grid>

                                {/* Stat Row 5 */}
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Puntos:</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ border: '1px solid rgb(42, 46, 56)' }}>
                                    <Typography style={{ color: 'rgb(155, 165, 180)' }}>Zen:</Typography>
                                </Grid>



                            </Grid>
                        )}

                    </Grid>
                </DialogContent>
                <Grid style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', backgroundColor: '#660000', border: '1px solid #330000' }}>
                    <Grid style={{ padding: 5 }}>
                        <Typography style={{ color: 'white' }}>Personaje Baneado:</Typography>
                    </Grid>
                    <Grid style={{ padding: 5 }}>
                        <Typography style={{ color: 'white' }}>Razon:</Typography>
                    </Grid>
                    <Grid style={{ padding: 5 }}>
                        <Typography style={{ color: 'white' }}>Personaje Baneado Hasta:</Typography>
                    </Grid>

                </Grid>
                <DialogActions style={{ justifyContent: 'center', backgroundColor: '#121418' }}>
                    <Button onClick={handleClose} style={{ background: '#333333', width: '100%' }} variant='contained' className='btn-cerrar' >
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    )
}

export default Micuenta